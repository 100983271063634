import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { BarChart3Icon } from "lucide-react"
import React from "react"
import { epoch, roundedNow, useAutoQuery } from "../utils"
import { Link } from "react-router-dom"
import { useSong } from "../stores/SongStore"
import { getTopSongs } from "../DbService"

export function ArtistTopSongCard({artistId}) {
    const start = epoch()
    const end = roundedNow()

    const {data: groups, isPending} = useAutoQuery(getTopSongs, {
        artistId: artistId,
        start: start,
        end: end,
        limit: 20,
    })

    if (isPending || groups.length === 0) {
        return
    }

    return <Card0 group={groups[0]}/>
}

function Card0({group}) {
    const {data: song, isPending} = useSong(group.entityId)

    if (isPending) {
        return
    }

    return (
        <Link to={`/track/${song.id}`}>
            <Card>
                <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-sm font-medium">Top Track</CardTitle>
                    <BarChart3Icon className="w-4 h-4 text-muted-foreground"/>
                </CardHeader>
                <CardContent>
                    <div className="text-2xl font-bold">{song.name}</div>
                    <p className="text-xs text-muted-foreground">{group.count} plays all time</p>
                </CardContent>
            </Card>
        </Link>
    )
}