import { create } from "zustand"
import { QueryClient } from "@tanstack/react-query"

export const queryClientStore = create((set) => ({
    queryClient: new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        },
    }),
}))