import {
    getAlbumGraphListenGroups,
    getArtistsGraphListenGroups,
    getDiscoveredArtistCountByYear,
    getSongGraphListenGroups,
    getSummary,
    getTopArtistsForGenre,
    getTopArtistsForOther,
    getTopNAlbumsByYears,
    getTopNArtistsByYears,
    getTopNewlyDiscoveredArtists as getTopNewlyDiscoveredArtistsDb,
    getTopNewlyDiscoveredRecordings as getTopNewlyDiscoveredRecordingsDb,
    getTopNGenresByYears,
    getTopNHoursByYear,
    getTopNSongByYears,
    getTopSongsForGenre,
    getTopSongsForOther,
    getTopYears,
} from "./DbClient"
import { DateTime } from "luxon"
import { groupBy, toMap } from "./utils"

export async function getTopSongs({artistId, albumId, genreId, start, end, limit}) {
    if (genreId != null) {
        return getTopSongsForGenre({genreId, start, end, limit})
    } else {
        return getTopSongsForOther({artistId, albumId, start, end, limit})
    }
}

export async function getTopArtists({genreId, start, end, limit}) {
    if (genreId != null) {
        return getTopArtistsForGenre({genreId, start, end, limit})
    } else {
        return getTopArtistsForOther({start, end, limit})
    }
}

export async function getTopNewlyDiscoveredArtists({discoveredAfter, limit}) {
    return getTopNewlyDiscoveredArtistsDb({discoveredAfter, limit})
}

export async function getTopNewlyDiscoveredSongs({discoveredAfter, limit}) {
    return getTopNewlyDiscoveredRecordingsDb({discoveredAfter, limit})
}

export async function getYears() {
    const topYearsPromise = getTopYears()
    const topArtistsPromise = getTopNArtistsByYears({n : 3})
    const topSongsPromise = getTopNSongByYears({n: 3})
    const topAlbumsPromise = getTopNAlbumsByYears({n: 3})
    const topGenresPromise = getTopNGenresByYears({n: 15})
    const topHoursPromise = getTopNHoursByYear({n: 1})
    const discoveredArtistsCountPromise = getDiscoveredArtistCountByYear()

    const topYears = await topYearsPromise
    const topArtistsByYear = groupBy(await topArtistsPromise, group => group.partition)
    const topSongsByYear = groupBy(await topSongsPromise, group => group.partition)
    const topAlbumsByYear = groupBy(await topAlbumsPromise, group => group.partition)
    const topGenresByYear = groupBy(await topGenresPromise, group => group.partition)
    const topHourByYear = toMap(await topHoursPromise, group => group.partition)
    const discoveredArtistsCountByYear = toMap(await discoveredArtistsCountPromise, group => group.year)

    return topYears
        .filter(group => group.count >= 10)
        .sort((a, b) => b.entityId - a.entityId)
        .map(group => {
            group.topArtists = topArtistsByYear.get(group.entityId) || []
            group.topSongs = topSongsByYear.get(group.entityId) || []
            group.topAlbums = topAlbumsByYear.get(group.entityId) || []
            group.topGenres = topGenresByYear.get(group.entityId) || []
            group.topHour = topHourByYear.get(group.entityId)
            group.discoveredArtistCount = discoveredArtistsCountByYear.get(group.entityId)?.count || 0

            return group
        })
}

export async function getArtistsGraph({start, end, distinctCount}) {
    const graphSettings = await getGraphSettings({start, end})
    const listenGroups = await getArtistsGraphListenGroups({
        start: graphSettings.start,
        end: graphSettings.end,
        datePart: graphSettings.interval,
        distinctCount,
    })
    return buildGraph(listenGroups, graphSettings)
}

export async function getAlbumsGraph({artistId, start, end, distinctCount}) {
    const graphSettings = await getGraphSettings({start, end, artistId})
    const listenGroups = await getAlbumGraphListenGroups({
        artistId,
        start: graphSettings.start,
        end: graphSettings.end,
        datePart: graphSettings.interval,
        distinctCount,
    })
    return buildGraph(listenGroups, graphSettings)
}

export async function getSongsGraph({artistId, albumId, start, end, distinctCount}) {
    const graphSettings = await getGraphSettings({start, end, artistId, albumId})
    const listenGroups = await getSongGraphListenGroups({
        artistId,
        albumId,
        start: graphSettings.start,
        end: graphSettings.end,
        datePart: graphSettings.interval,
        distinctCount,
    })
    return buildGraph(listenGroups, graphSettings)
}


async function getGraphSettings({start, end, artistId, albumId}) {
    const summary = await getSummary({start, end, artistId, albumId})

    start = summary.firstListen ? DateTime.fromMillis(summary.firstListen) : start
    end = summary.lastListen ? DateTime.fromMillis(summary.lastListen) : end

    const interval = getInterval(start, end)

    start = truncate(start, interval)
    end = truncate(end, interval)

    return {start, end, interval}
}

function getInterval(start, end) {
    const duration = end.diff(start, ["days"])

    // A little more than a year to force year periods to be weekly
    if (duration.days >= 7 * 53) {
        return "MONTHS"
        // A little more than a month to force month periods to be weekly
    } else if (duration.days >= 32) {
        return "WEEKS"
    } else {
        return "DAYS"
    }
}

function truncate(dateTime, interval) {
    switch (interval) {
        case "MONTHS":
            return dateTime.startOf("month").startOf("day")
        case "WEEKS":
            return dateTime.startOf("week").startOf("day")
        case "DAYS":
            return dateTime.startOf("day")
        default:
            throw new Error("Unsupported operation")
    }
}

function buildGraph(listenGroups, graphSettings) {
    const listenGroupsByKey = {}
    const entityIds = new Set()

    for (const listenGroup of listenGroups) {
        entityIds.add(listenGroup.entityId)
        listenGroupsByKey[[listenGroup.entityId, listenGroup.partition]] = listenGroup
    }

    const ticks = buildTicks(graphSettings)

    const lines = [...entityIds].map(entityId => {
        const points = ticks.map(tick => {
            const key = [entityId, tick.toMillis()]
            const listenGroup = listenGroupsByKey[key]
            let point
            if (listenGroup != null) {
                point = {time: tick.toMillis(), count: Number(listenGroup.count), durationMs: listenGroup.durationMs}
            } else {
                point = {time: tick.toMillis(), count: 0, durationMs: 0}
            }
            return point
        })
        return {entityId: entityId, points}
    })

    return {lines, graphSettings}
}

function buildTicks(graphSettings) {
    const {start, end, interval} = graphSettings

    const result = []
    let current = start

    while (current < end) {
        result.push(current)
        current = current.plus({[interval]: 1})
    }

    return result
}