import { create } from "zustand"
import { queryClientStore } from "./QueryClientStore"

export const tokenStore = create(_ => loadTokenState())

function loadTokenState() {
    const token = localStorage.getItem("token")
    return {
        token: token,
        hasToken: token !== null,
    }
}

export function setToken(token) {
    localStorage.setItem("token", token)
    refreshTokenState()
}

export function clearToken() {
    localStorage.removeItem("token")
    // Clear all queries on log out
    queryClientStore.getState().queryClient.clear()
    refreshTokenState()
}

function refreshTokenState() {
    tokenStore.setState(loadTokenState())
}
