import { Graph } from "./Graph"
import { useSongs } from "../../stores/SongStore"

export function SongGraph({graph, className}) {
    const entityIds = graph.lines
        .map(line => line.entityId)
        .filter(id => id !== -1)
    const results = useSongs(entityIds)

    const songsById = {}
    for (const result of results) {
        if (!result.isPending) {
            const song = result.data
            songsById[song.id] = song
        }
    }

    const nameFormatter = (id) => {
        if (id === -1) {
            return "Other"
        }

        const song = songsById[id]

        if (song) {
            return song.name
        } else {
            return "Loading..."
        }
    }

    return (
        <Graph
            graph={graph}
            nameFormatter={nameFormatter}
            className={className}
        />
    )
}