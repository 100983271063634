import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts"
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../ui/chart"
import { DateTime } from "luxon"
import React from "react"

export function Graph({graph, nameFormatter, className}) {
    let tickFormat = getTickFormat(graph)

    let ticks = {}
    for (let line of graph.lines) {
        for (let point of line.points) {
            if (!(point.time in ticks)) {
                ticks[point.time] = {time: point.time}
            }
            ticks[point.time][line.entityId] = point.count
        }
    }

    let colorIndex = 1
    let areas = graph.lines.map((line => {
        let color
        if (line.entityId === "-1") {
            color = "chart-other"
        } else {
            color = `chart-${colorIndex}`
            colorIndex++
        }

        const entityId = line.entityId

        return (
            <Area
                dataKey={entityId}
                name={nameFormatter(entityId)}
                type="monotone"
                fill={`hsl(var(--${color}))`}
                fillOpacity={0.4}
                stroke={`hsl(var(--${color}))`}
                stackId="a"
                isAnimationActive={false}
                key={entityId}
            />
        )
    }))



    return (
        <ChartContainer config={{}} className={className}>
            <AreaChart
                accessibilityLayer
                data={Object.values(ticks)}
                margin={{
                    left: 12,
                    right: 12,
                }}
            >
                <CartesianGrid vertical={false}/>
                <XAxis
                    dataKey="time"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={value => DateTime.fromMillis(value).toFormat(tickFormat)}
                />
                <YAxis
                    tickMargin={4}
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent indicator="dot"/>}
                    dataKey="time"
                    labelKey="time"
                    labelFormatter={(__, arr) => {
                        let time = arr.map(tick => tick.payload.time)[0]
                        return DateTime.fromMillis(time).toFormat(tickFormat)
                    }}
                />
                {areas}
            </AreaChart>
        </ChartContainer>
    )
}

function getTickFormat(graph) {
    let times = graph.lines
        .flatMap(line => line.points)
        .map(point => point.time)
    let min = DateTime.fromMillis(Math.min(...times))

    let current = DateTime.now()

    let needsYear = min.year !== current.year

    const interval = graph.graphSettings.interval
    if (interval === "MONTHS") {
        if (needsYear) {
            return "MMM yyyy"
        } else {
            return "MMM"
        }
    } else if (interval === "WEEKS" || interval === "DAYS") {
        if (needsYear) {
            return "MMM d yyyy"
        } else {
            return "MMM d"
        }
    } else {
        return "MMM d yyyy"
    }
}