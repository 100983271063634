import { Graph } from "./Graph"
import { getAlbumName } from "../../utils"
import { useAlbums } from "../../stores/AlbumStore"

export function AlbumGraph({graph, className}) {
    const entityIds = graph.lines
        .map(line => line.entityId)
        .filter(id => id !== -1)
    const results = useAlbums(entityIds)

    const albumsById = {}
    for (const result of results) {
        if (!result.isPending) {
            const artist = result.data
            albumsById[artist.id] = artist
        }
    }

    const nameFormatter = (id) => {
        if (id === -1) {
            return "Other"
        }

        const album = albumsById[id]

        if (album) {
            return getAlbumName(album)
        } else {
            return "Loading..."
        }
    }

    return (
        <Graph
            graph={graph}
            nameFormatter={nameFormatter}
            className={className}
        />
    )
}