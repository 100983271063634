import React from "react"
import { Link } from "react-router-dom"

export function ArtistCreditSegment({segment}) {
    if (segment.artistId) {
        return (
            <Link to={`/artist/${segment.artistId}`}
                  className="text-xl text-gray-600 dark:text-gray-300 hover:text-indigo-700 dark:hover:text-purple-400 transition-colors">
                {segment.text}
            </Link>
        )
    } else {
        return (
            <p className="text-xl text-gray-600 dark:text-gray-300">{segment.text}</p>
        )
    }
}