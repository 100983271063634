import React from "react"
import { useAutoQuery } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { AlbumCard } from "./AlbumCard"
import { getTopAlbums } from "../DbClient"

export function TopAlbums({artistId, start, end}) {
    const {data: groups, isPending} = useAutoQuery(getTopAlbums, {
        artistId,
        start,
        end,
        limit: 10,
    })

    if (isPending || groups.length === 0) {
        return null
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Albums & EPs</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {groups.map((group, index) =>
                        <AlbumCard group={group} index={index} key={group.entityId}/>)}
                </div>
            </CardContent>
        </Card>
    )
}
