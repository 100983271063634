import React from "react"
import { useAutoQuery } from "../utils"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { ArtistCard } from "./ArtistCard"
import { getTopArtists } from "../DbService"

export function TopArtists({genreId, start, end}) {
    const {data: groups, isPending} = useAutoQuery(getTopArtists, {
        genreId,
        start,
        end,
        limit: 10,
    })

    if (isPending) {
        return
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Top Artists</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center gap-4">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {groups.map((group, index) =>
                        <ArtistCard group={group} index={index} key={group.entityId}/>)}
                </div>
            </CardContent>
        </Card>
    );
}
