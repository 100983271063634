import { epoch, roundedNow } from "../utils"
import { useParams } from "react-router-dom"
import { Badge } from "../components/ui/badge"
import { TopArtists } from "../components/TopArtists"
import { useGenre } from "../stores/GenreStore"
import { TopSongs } from "../components/TopSongs"

function Header({genreId}) {
    const {data: genre, isPending} = useGenre(genreId)


    if (isPending) {
        return
    }

    return (
        <div className="flex flex-row items-center justify-between">
            <h1 className="text-4xl font-bold">{genre.name}</h1>
            <Badge variant="outline" className="text-lg">Genre Insights</Badge>
        </div>
    )
}

export function GenrePage() {
    const {genreId} = useParams()

    const start = epoch()
    const end = roundedNow()

    return (
        <div className="flex flex-col gap-4">
            <Header genreId={genreId}/>
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                <TopSongs genreId={genreId} start={start} end={end} limit="10"/>
                <TopArtists genreId={genreId} start={start} end={end} limit="10"/>
            </div>
        </div>
    )
}