import { HomePage } from "./pages/HomePage"
import { AboutPage } from "./pages/AboutPage"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ArtistPage } from "./pages/ArtistPage"
import { AuthPage } from "./pages/AuthPage"
import { JourneyPage } from "./pages/JourneyPage"
import { Layout } from "./components/Layout"
import { AlbumPage } from "./pages/AlbumPage"
import { SongPage } from "./pages/SongPage"
import { LoginPage } from "./pages/LoginPage"
import { SpotifyDataUploadPage } from "./pages/SpotifyDataUploadPage"
import { GenrePage } from "./pages/GenrePage"
import { SearchPage } from "./pages/SearchPage"
import { UserPage } from "./pages/UserPage"
import { YearPage } from "./pages/YearPage"
import { databaseStore } from "./stores/DatabaseStore"
import { useStoreWithEqualityFn } from "zustand/traditional"
import { LoadingPage } from "./pages/LoadingPage"
import { tokenStore } from "./stores/ApiTokenStore"

export function App() {
    const {hasToken} = useStoreWithEqualityFn(tokenStore)
    const {loading: databaseLoading} = useStoreWithEqualityFn(databaseStore)

    if (hasToken && databaseLoading) {
        return (
            <Router>
                <Layout>
                    <LoadingPage/>
                </Layout>
            </Router>
        )
    }

    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/u/:username" element={<UserPage/>}/>
                    <Route path="/artist/:artistId" element={<ArtistPage/>}/>
                    <Route path="/album/:albumId" element={<AlbumPage/>}/>
                    <Route path="/track/:songId" element={<SongPage/>}/>
                    <Route path="/genre/:genreId" element={<GenrePage/>}/>
                    <Route path="/year/:year" element={<YearPage/>}/>
                    <Route path="/auth" element={<AuthPage/>}/>
                    <Route path="/journey" element={<JourneyPage/>}/>
                    <Route path="/search" element={<SearchPage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/spotify-data-upload" element={<SpotifyDataUploadPage/>}/>
                </Routes>
            </Layout>
        </Router>
    )
}
