import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { useQuery } from "@tanstack/react-query"
import { DateTime } from "luxon"

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}

export function useAutoQuery(queryFn, ...args) {
    return useQuery({
        queryKey: getQueryKey(queryFn, args),
        queryFn: () => queryFn(...args),
        throwOnError: true,
    })
}

export function invalidateAutoQuery(queryClient, queryFn, ...args) {
    let queryKey = getQueryKey(queryFn, args)
    queryClient.invalidateQueries(queryKey)
}

export function getQueryKey(queryFn, args) {
    return ["auto", queryFn.name, ...args]
}

export function epoch() {
    return DateTime.fromMillis(0)
}

export function roundedNow() {
    return DateTime.local().endOf("hour")
}

export function formatTrackDuration(durationMs) {
    return DateTime.fromMillis(durationMs).toFormat("m:ss")
}

export function formatListenDuration(milliseconds) {
    const oneMinute = 60000
    return formatCount(milliseconds / oneMinute) + " min"
}

export function formatCount(count) {
    if (typeof count === "bigint") {
        count = Number(count)
    }

    if (count >= 1000) {
        return (count / 1000).toPrecision(3) + "k"
    } else {
        return Math.round(count)
    }
}

export function formatDateHumanReadable(date) {
    return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
    })
}

export function formatHour(hour) {
    const date = new Date(2000, 0, 1, hour)
    return date.toLocaleTimeString(undefined, {
        hour: "numeric",
        hour12: true,
    })
}

export function formatReleaseDate(releaseDate) {
    const {year, month, day} = releaseDate

    if (year && month && day) {
        let dateTime = DateTime.fromObject({year, month, day})
        return dateTime.toFormat("MMMM d, yyyy")
    } else if (year && month) {
        let dateTime = DateTime.fromObject({year, month})
        return dateTime.toFormat("MMMM yyyy")
    } else if (year) {
        let dateTime = DateTime.fromObject({year})
        return dateTime.toFormat("yyyy")
    } else {
        return null
    }
}

export function getArtistName(artist) {
    // const aliasByLocale = {}
    //
    // artist.aliases
    //     .filter(alias => alias.primaryForLocale)
    //     .forEach(alias => aliasByLocale[alias.locale] = alias.name)
    //
    // for (const locale of navigator.languages) {
    //     const alias = aliasByLocale[locale]
    //     if (alias != null) {
    //         return alias
    //     }
    // }

    return artist.name
}

export function getAlbumName(album) {
    return album.name
}

export function getFlattenedArtistCredit(artistCredit) {
    return artistCredit.segments
        .map(segment => segment.text)
        .join("")
}

export async function sleep(duration) {
    await new Promise(resolve => setTimeout(resolve, duration.as("milliseconds")))
}

export function groupBy(array, keySelector) {
    return array.reduce((map, item) => {
        const groupKey = keySelector(item);
        if (!map.has(groupKey)) {
            map.set(groupKey, []);
        }
        map.get(groupKey).push(item);
        return map;
    }, new Map());
}

export function toMap(array, keySelector) {
    return new Map(array.map(item => [keySelector(item), item]))
}

