import React from "react"
import { Link, useParams } from "react-router-dom"
import { TopAlbums } from "../components/TopAlbums"
import { ListenOnSpotifyButton } from "../components/ListenOnSpotifyButton"
import { ArtistRankCard } from "../components/ArtistRankCard"
import { ArtistListeningTimeCard } from "../components/ArtistListeningTimeCard"
import { ArtistTotalListensCard } from "../components/ArtistTotalListensCard"
import { ArtistTopSongCard } from "../components/ArtistTopSongCard"
import { epoch, getArtistName, roundedNow, useAutoQuery } from "../utils"
import { useArtist } from "../stores/ArtistStore"
import { getAlbumsGraph } from "../DbService"
import { AlbumGraph } from "../components/graph/AlbumGraph"
import { TopSongs } from "../components/TopSongs"

export function ArtistPage() {
    const {artistId} = useParams()
    const start = epoch()
    const end = roundedNow()

    return (
        <div className="flex flex-col items-center gap-4 w-full">
            <Header artistId={artistId}/>
            <Summary artistId={artistId}/>
            <TopAlbumsGraph artistId={artistId}/>
            <div className="grid sm:grid-colds-1 md:grid-cols-2 gap-4 w-full">
                <TopAlbums artistId={artistId} start={start} end={end}/>
                <TopSongs artistId={artistId} start={start} end={end}/>
            </div>
        </div>
    )
}

function Header({artistId}) {
    const {data: artist, isPending} = useArtist(artistId)

    if (isPending) {
        return null
    }

    const name = getArtistName(artist)

    return (
        <div className="flex flex-row gap-6 items-start">
            <img
                src={artist.images[0]?.url}
                alt={name}
                className="rounded-full w-32 h-32 md:w-48 md:h-48 object-cover"
            />
            <div className="flex flex-col gap-4">
                <h1 className="text-3xl md:text-4xl font-bold">{name}</h1>
                <div className="text-xl text-gray-600 dark:text-gray-300">
                    {artist.genres
                        .map(genre => <GenreLink genre={genre} key={genre.id}/>)
                        .reduce((prev, curr) => (prev === null ? [curr] : [...prev, ", ", curr]), null)
                    }
                </div>
                {artist.spotifyUrl != null ? <ListenOnSpotifyButton url={artist.spotifyUrl}/> : null}
            </div>
        </div>
    )
}

function GenreLink({genre}) {
    return (
        <Link to={`/genre/${genre.id}`} className="hover:text-indigo-700">
            {genre.name}
        </Link>
    )
}

function Summary({artistId}) {
    return (
        <div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-4">
            <ArtistTotalListensCard artistId={artistId}/>
            <ArtistListeningTimeCard artistId={artistId}/>
            <ArtistTopSongCard artistId={artistId}/>
            <ArtistRankCard artistId={artistId}/>
        </div>
    )
}

function TopAlbumsGraph({artistId}) {
    const start = epoch()
    const end = roundedNow()
    const {data: graph, isPending} = useAutoQuery(getAlbumsGraph, {
        artistId,
        start,
        end,
        distinctCount: 4,
    })

    if (isPending) {
        return
    }

    return (
        <AlbumGraph
            graph={graph}
            className="w-full h-[25rem]"
        />
    )
}
