import { useLocation } from "react-router-dom"
import { useAutoQuery } from "../utils"
import { search } from "../ApiClient"
import { Disc, DiscAlbum, MicVocal } from "lucide-react"
import { Card, CardContent, CardHeader } from "../components/ui/card"
import React from "react"
import { ArtistCard } from "../components/ArtistCard"
import { AlbumCard } from "../components/AlbumCard"

function Artists({artists}) {
    if (artists.length === 0) {
        return null
    }

    return (
        <Card>
            <CardHeader className="flex flex-row gap-2">
                <h2 className="text-3xl font-bold">Artists</h2>
                <MicVocal className="h-[2rem] w-[2rem]"/>
            </CardHeader>
            <CardContent className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4">
                {artists.map(artist => <ArtistCard group={artist}/>)}
            </CardContent>
        </Card>
    )
}

function Recordings({recordings}) {
    if (recordings.length === 0) {
        return null
    }

    return (
        <Card>
            <CardHeader className="flex flex-row gap-2">
                <h2 className="text-3xl font-bold">Tracks</h2>
                <Disc className="h-[2rem] w-[2rem]"/>
            </CardHeader>
            <CardContent className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4">
                {/*{recordings.map(recording => <SongCard group={recording}/>)}*/}
            </CardContent>
        </Card>
    )
}

function ReleaseGroups({releaseGroups}) {
    if (releaseGroups.length === 0) {
        return null
    }

    return (
        <Card>
            <CardHeader className="flex flex-row gap-2">
                <h2 className="text-3xl font-bold">Albums & EPs</h2>
                <DiscAlbum className="h-[2rem] w-[2rem]"/>
            </CardHeader>
            <CardContent className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-2 gap-4">
                {releaseGroups.map(releaseGroup => <AlbumCard group={releaseGroup}/>)}
            </CardContent>
        </Card>
    )
}

function Header({query}) {
    return (
        <div className="flex items-center">
            <div>
                <h1 className="text-3xl font-bold">Search Results</h1>
                <p className="text-lg text-gray-500 dark:text-gray-400">for "{query}"</p>
            </div>
        </div>
    )
}

export function SearchPage() {
    const location = useLocation()
    const query = new URLSearchParams(location.search).get("q")

    const {data: results, isPending} = useAutoQuery(search, query)

    if (isPending) {
        return
    }

    return (
        <div className="flex flex-col gap-8 w-full">
            <Header query={query}/>
            <Artists artists={results.artists}/>
            <Recordings recordings={results.recordings}/>
            <ReleaseGroups releaseGroups={results.releaseGroups}/>
        </div>
    )

}