import { databaseStore } from "./stores/DatabaseStore"
import camelcaseKeys from "camelcase-keys"
import { DateTime } from "luxon"

export function getTopYears() {
    const sql = `
        WITH listen AS (
            SELECT
                EXTRACT(YEAR FROM timestamp) as entity_id,
                null as partition,
                null as rank,
                duration_ms,
                weight
            FROM song_listen
        )
        SELECT *
        FROM top_entities()
    `
    return query(sql)
}

export function getTopArtistsForOther({start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM artist_listen
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        start,
        end,
        limit,
    )
}

export function getTopArtistsForGenre({genreId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                l.entity_id,
                l.timestamp,
                l.duration_ms,
                sg.weight
            FROM artist_listen l
            JOIN song_genre sg ON l.song_id = sg.song_id
            WHERE
                genre_id = ? AND
                timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        genreId,
        start,
        end,
        limit,
    )
}

export async function getTopNewlyDiscoveredArtists({discoveredAfter, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                *,
                MIN(timestamp) OVER (PARTITION BY artist_id) as artist_discovery
            FROM artist_listen
            QUALIFY artist_discovery >= ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        discoveredAfter,
        limit,
    )
}

export async function getTopNewlyDiscoveredRecordings({discoveredAfter, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                *,
                MIN(timestamp) OVER (PARTITION BY artist_id) as song_discovery
            FROM song_listen
            QUALIFY song_discovery >= ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        discoveredAfter,
        limit,
    )
}

export function getTopAlbums({artistId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM album_listen(artist_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp    
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        artistId,
        start,
        end,
        limit,
    )
}

export function getTopSongsForOther({artistId, albumId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen(artist_id := ?, album_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp    
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        artistId,
        albumId,
        start,
        end,
        limit,
    )
}

export function getTopSongsForGenre({genreId, start, end, limit}) {
    const sql = `
        WITH listen AS (
            SELECT
                sl.song_id as entity_id,
                sl.timestamp,
                sl.duration_ms,
                sg.weight
            FROM song_listen sl
            JOIN song_genre sg ON sl.song_id = sg.song_id   
            WHERE
                genre_id = ? AND
                timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM top_entities()
        LIMIT ?
    `
    return query(
        sql,
        genreId,
        start,
        end,
        limit,
    )
}

export function getTopGenres({start, end, limit}) {
    const sql = `
        SELECT
            genre_id,
            genre_id as entity_id,
            COUNT(*) as count,
            SUM(duration_ms) as duration_ms
        FROM song_listen l
        JOIN song_genre sg ON l.song_id = sg.song_id
        WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        GROUP BY genre_id
        ORDER BY count DESC
        LIMIT ?
    `
    return query(
        sql,
        start,
        end,
        limit,
    )
}

export async function getSongCount({start, end, artistId, albumId}) {
    const sql = `
        SELECT COUNT(DISTINCT song_id)
        FROM song_listen(artist_id := ?, album_id := ?)
        WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
    `
    return querySingleColumn(sql, artistId, albumId, start, end)
}

export async function getDiscoveredArtistCount({start, end}) {
    const sql = `
        WITH artist_discovery AS (
            SELECT
                artist_id, MIN (timestamp) as timestamp
            FROM artist_listen
            GROUP BY artist_id
        )
        SELECT COUNT (*)
        FROM artist_discovery
        WHERE timestamp BETWEEN ?:: timestamp AND ?:: timestamp
    `
    return querySingleColumn(sql, start, end)
}

export async function getSummary({start, end, artistId, albumId}) {
    const sql = `
        SELECT
            COUNT(*) as count,
            COALESCE(SUM(duration_ms), 0) as duration_ms,
            MIN(timestamp) as first_listen,
            MAX(timestamp) as last_listen
        FROM song_listen(artist_id := ?, album_id := ?)
        WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
    `
    return querySingle(
        sql,
        artistId,
        albumId,
        start,
        end,
    )
}

export function getSongSummary({songId, start, end}) {
    const sql = `
        WITH relevant_listen AS (
            SELECT
                *
            FROM song_listen l
            WHERE
                l.timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                l.song_id = ?
        ), listen_count AS (
            SELECT COALESCE(COUNT(*), 0)
            FROM relevant_listen
        ), duration_ms AS (
            SELECT COALESCE(SUM(duration_ms), 0)
            FROM relevant_listen
        ), rank AS (
            WITH song_listen_group AS (
                SELECT
                    song_id,
                    COUNT(*) as count
                FROM song_listen
                WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
                GROUP BY song_id
            ), ranked_song AS (
                SELECT
                    song_id,
                    RANK() OVER (ORDER BY count DESC) as rank
                FROM song_listen_group
            )
            SELECT rank
            FROM ranked_song
            WHERE song_id = ?
        ), first_listen AS (
            SELECT MIN(timestamp)
            FROM relevant_listen
        ), last_listen AS (
            SELECT MAX(timestamp)
            FROM relevant_listen
        )
        SELECT
            (SELECT * FROM listen_count) as listen_count,
            (SELECT * FROM duration_ms) as duration_ms,
            (SELECT * FROM rank) as rank,
            (SELECT * FROM first_listen) as first_listen,
            (SELECT * FROM last_listen) as last_listen
    `
    return querySingle(
        sql,
        start,
        end,
        songId,
        start,
        end,
        songId,
    )
}

export function getArtistSummary({artistId, start, end}) {
    const sql = `
        WITH relevant_listen AS (
            SELECT
                *
            FROM artist_listen
            WHERE
                timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                artist_id = ?
        ), listen_count AS (
            SELECT COALESCE(COUNT(*), 0)
            FROM relevant_listen
        ), duration_ms AS (
            SELECT COALESCE(SUM(duration_ms), 0)
            FROM relevant_listen
        ), song_count AS (
            SELECT COALESCE(COUNT(DISTINCT song_id), 0)
            FROM relevant_listen
        ), album_count AS (
            SELECT COALESCE(COUNT(DISTINCT album_id), 0)
            FROM relevant_listen l
            JOIN song_album sa ON l.song_id = sa.song_id
        ), rank AS (
            WITH artist_listen_group AS (
                SELECT
                    artist_id,
                    COUNT(*) as count
                FROM artist_listen l
                WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
                GROUP BY artist_id
            ), ranked_artist AS (
                SELECT
                    artist_id,
                    RANK() OVER (ORDER BY count DESC) as rank
                FROM artist_listen_group
            )
            SELECT rank
            FROM ranked_artist
            WHERE artist_id = ?
        )
        SELECT
            (SELECT * FROM listen_count) as listen_count,
            (SELECT * FROM duration_ms) as duration_ms,
            (SELECT * FROM song_count) as song_count,
            (SELECT * FROM album_count) as album_count,
            (SELECT * FROM rank) as rank
    `
    return querySingle(
        sql,
        start,
        end,
        artistId,
        start,
        end,
        artistId,
    )
}

export function getAlbumSummary({albumId, start, end}) {
    const sql = `
        WITH relevant_listen AS (
            SELECT
                *
            FROM album_listen l
            WHERE
                l.timestamp BETWEEN ?::timestamp AND ?::timestamp AND
                l.album_id = ?
        ), listen_count AS (
            SELECT COALESCE(COUNT(*), 0)
            FROM relevant_listen
        ), duration_ms AS (
            SELECT COALESCE(SUM(duration_ms), 0)
            FROM relevant_listen
        ), rank AS (
            WITH album_listen_group AS (
                SELECT
                    album_id,
                    COUNT(*) as count
                FROM album_listen
                WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
                GROUP BY album_id
            ), ranked_album AS (
                SELECT
                    album_id,
                    RANK() OVER (ORDER BY count DESC) as rank
                FROM album_listen_group
            )
            SELECT rank
            FROM ranked_album
            WHERE album_id = ?
        ), first_listen AS (
            SELECT MIN(timestamp)
            FROM relevant_listen
        ), last_listen AS (
            SELECT MAX(timestamp)
            FROM relevant_listen
        )
        SELECT
            (SELECT * FROM listen_count) as listen_count,
            (SELECT * FROM duration_ms) as duration_ms,
            (SELECT * FROM rank) as rank,
            (SELECT * FROM first_listen) as first_listen,
            (SELECT * FROM last_listen) as last_listen
    `
    return querySingle(
        sql,
        start,
        end,
        albumId,
        start,
        end,
        albumId,
    )
}

export async function getTopNArtistsByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM artist_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getTopNSongByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getTopNAlbumsByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM album_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getTopNGenresByYears({n}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM genre_listen
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}

export async function getDiscoveredArtistCountByYear() {
    const sql = `
        WITH artist_discovery AS (
            SELECT
                artist_id,
                MIN(timestamp) as timestamp
            FROM artist_listen
            GROUP BY artist_id
        )
        SELECT
            EXTRACT(YEAR FROM timestamp) as year,
            COUNT(*) as count
        FROM artist_discovery
        GROUP BY year
    `
    return query(sql)
}

export async function getTopNHoursByYear({n}) {
    const sql = `
        WITH listen AS (
            SELECT
                EXTRACT(HOUR FROM timestamp) AS entity_id,
                duration_ms,
                timestamp
            FROM song_listen   
        )
        SELECT *
        FROM top_n_entities_by_year(?)
    `
    return query(sql, n)
}


export async function getArtistsGraphListenGroups({distinctCount, start, end, datePart}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM artist_listen
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM graph(?, ?)
    `
    return query(
        sql,
        start,
        end,
        distinctCount,
        datePart,
    )
}

export async function getAlbumGraphListenGroups({artistId, start, end, distinctCount, datePart}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM album_listen(artist_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM graph(?, ?)
    `
    return query(
        sql,
        artistId,
        start,
        end,
        distinctCount,
        datePart,
    )
}

export async function getSongGraphListenGroups({artistId, albumId, start, end, distinctCount, datePart}) {
    const sql = `
        WITH listen AS (
            SELECT *
            FROM song_listen(artist_id := ?, album_id := ?)
            WHERE timestamp BETWEEN ?::timestamp AND ?::timestamp
        )
        SELECT *
        FROM graph(?, ?)
    `
    return query(
        sql,
        artistId,
        albumId,
        start,
        end,
        distinctCount,
        datePart,
    )
}

async function query(sql, ...args) {
    const {database, loading} = databaseStore.getState()
    if (loading) {
        return null
    }

    args = args.map(mapArg)

    const conn = await database.connect()
    const statement = await conn.prepare(sql)
    try {
        const result = await statement.query(...args)
        return result.toArray()
            .map(row => row.toJSON())
            .map(camelcaseKeys)
    } catch (e) {
        throw e
    }
}

async function querySingle(sql, ...args) {
    const results = await query(sql, ...args)
    if(results.length <= 1) {
        return results[0]
    } else {
        throw new Error(`Expected a single row, got ${results.length}`)
    }
}

async function querySingleColumn(sql, ...args) {
    const result = await querySingle(sql, ...args)
    const keys = Object.keys(result)
    if(keys.length === 1) {
        return result[keys[0]]
    } else {
        throw new Error(`Expected a single column, got ${keys.length} columns`)
    }
}

function mapArg(arg) {
    if (DateTime.isDateTime(arg)) {
        return arg.toSQL({includeOffset: false})
    } else {
        return arg
    }
}
