import { create, keyResolver, windowedFiniteBatchScheduler } from "@yornaath/batshit"
import { getGenres } from "../ApiClient"
import { useQuery } from "@tanstack/react-query"

const genres = create({
    fetcher: getGenres,
    resolver: keyResolver("id"),
    scheduler: windowedFiniteBatchScheduler({
        windowMs: 10,
        maxBatchSize: 100,
    }),
})

export function useGenre(id) {
    id = Number(id)
    return useQuery({
        queryKey: ["genres", id],
        queryFn: async () => genres.fetch(id),
        throwOnError: true,
    })
}